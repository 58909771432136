<div>

</div>
<div class="container" [ngClass]="{ 'fondo-gris': !ingreso }">
    <div class="item-container" #animatedSection [ngClass]="{'spaceInUp': isInView, ' hidden-animation': !isInView}">
        <ng-container *ngFor="let item of destacadas" class="">
            <div [id]="'destacado-'+item.id" class="item" *ngIf="item.destacada && item.estado">
                <div class="img-icon">
                    <img [src]="item.path" onerror="this.src='./assets/img/none.png'" alt="icono beneficio">
                </div>
                <div class="text-container">
                    <h2 class="quicksand-regular">{{item.label}}</h2>
                </div>
                <div class="btn-container">
                    <button (click)="redirect(item.link)" class="more-info-btn nunito-regular zoomOut"><a>MÁS INFO</a></button>
                </div>

            </div>

        </ng-container>
    </div>

</div>
<div class="container-f" *ngIf="ingreso">
    <div class="subcontainer-f">
        <div *ngFor="let item of noDestacadas" #animatedSection2 class="zoomIn"
            [ngClass]="{'puffIn': isInView, ' hidden-animation': !isInView}">
            <div class="item-f" *ngIf="!item.destacada && item.estado" (click)="redirect(item.link)">
                <div class="container-img-f">
                    <img [src]="item.path" onerror="this.src='./assets/img/none.png'" class='img-icon-f '
                        alt="icono beneficio">
                </div>
                <div class="text-container-f quicksand-regular">
                    {{item.label}}
                </div>

            </div>

        </div>
    </div>
</div>