import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { FormasDeContactoDosComponent } from 'src/app/shared/widgets/formas-de-contacto/formas-de-contacto-dos.component';
import { BuscadorCursosTematicoComponent } from 'src/app/shared/components/buscador-cursos-tematico/buscador-cursos-tematico.component';
import { PortadaComponent } from 'src/app/shared/widgets/portada/portada.component';


@Component({
  selector: 'app-detalle-curso',
  templateUrl: './detalle-curso.component.html',
  styleUrls: ['./detalle-curso.component.scss']
})
export class DetalleCursoComponent implements OnInit {


  id: any
  area = {};
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1!: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2!: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3!: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4!: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5!: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6!: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7!: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8!: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9!: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10!: ViewContainerRef;

  viewContainerRefA(index: number): ViewContainerRef | undefined {
    let viewContainerRefs: ViewContainerRef[] = [
      this.viewContainerRef1,
      this.viewContainerRef2,
      this.viewContainerRef3,
      this.viewContainerRef4,
      this.viewContainerRef5,
      this.viewContainerRef6,
      this.viewContainerRef7,
      this.viewContainerRef8,
      this.viewContainerRef9,
      this.viewContainerRef10,
    ];
    return viewContainerRefs[index - 1];
  }
  areaUrl = "";

  titulo: any
  subitulo: any
  itemName: string;
  tieneTyS: boolean = true

  infoAreaRecomendada: any
  language = ''



  constructor(private aRouter: ActivatedRoute, private services: GeneralService, public router: Router, private form: FormBuilder, private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    this.init()
  }

   init(){
    var url = decodeURIComponent(window.location.href);
    this.id = url.replace(environment.frontUrl + '/' + this.language + '/', '');    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      if (res.data[0]) this.area = res.data[0].area_tematica;
      Object.keys(res.data[0].pages).forEach((key) => {
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
            this.newComponent(Number(key), arrayValue[key][0]?.entityType, data1.data)
          });
        }
      });
    })
   }

  refBuscador: any

  newComponent(index: number, type: string, info: any) {
    console.log(info)
    switch (type) {
      case "PortadaAreas":
      case "Portada": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        // ref.location.nativeElement.classList.add('col-lg-' + width);
        this.titulo = info[0].cover.title
        this.subitulo = info[0].cover.subtitle
        ref.instance.area = this.area;
        ref.instance.data = info[0];
        ref.instance.marginTitle = 75;
        ref.instance.heightDesk = "200px";
        ref.instance.heightMob = "250px";
        ref.changeDetectorRef.detectChanges();

      }
        break;


      case 'Buscador': {

        let factory = this.componentFactoryResolver.resolveComponentFactory(BuscadorCursosTematicoComponent);
        this.refBuscador = (this.viewContainerRefA(index)).createComponent(factory);
        this.refBuscador.instance.titulo = "Educación continua";
        this.refBuscador.instance.translateY = 75;
        this.refBuscador.instance.padding = "38.6px 35px";
        this.refBuscador.instance.paddingMob = "35px";
        this.refBuscador.instance.infoAreaRecomendada = this.infoAreaRecomendada;
        this.refBuscador.instance.data = info;
        this.refBuscador.instance.area = this.area;
        this.refBuscador.changeDetectorRef.detectChanges();

        break;
      }

      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info;
        ref.instance.fondo = "Fondo-web-continua.png";
        ref.instance.esCurso = true

      }
        break;
      case "AreaRecomendada":
        if (this.refBuscador) {
          this.refBuscador.instance.infoAreaRecomendada = info;
          this.refBuscador.changeDetectorRef.detectChanges();
        }

    }






  }


  ngOnInit(): void {

  }


}
