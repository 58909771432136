import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { ContactoComponent } from '../../components/contacto/contacto.component';
import { CardsGeneralDosComponent } from 'src/app/shared/widgets/cards-general-dos/cards-general-dos.component';
import { CardsGeneralComponent } from 'src/app/shared/widgets/cards-general/cards-general.component';
import { CarrouselGeneralComponent } from 'src/app/shared/widgets/carrousel-general/carrousel-general.component';
import { CarrouselImgComponent } from 'src/app/shared/widgets/carrousel-img/carrousel-img.component';
import { ListaDeLinksComponent } from 'src/app/shared/widgets/lista-de-links/lista-de-links.component';

@Component({
  selector: 'app-content-generic-page',
  templateUrl: './content-generic-page.component.html',
  styleUrls: ['./content-generic-page.component.scss']
})
export class ContentGenericPageComponent implements OnInit {
  @Input() entity: any;
  @Input() article: any;
  @Input() postsRelations: any;
  @Input() carrer: string;
  @Input() carrerType: string;
  @Input() venue: string;
  @Input() carrerInfo: any;
  @Input() fare: string;
  @Input() type: number = 1;
  @Input() countFamily: number = 1;
  @Input() isNotice: boolean = false;
  @Input() isSinglePage: boolean = false;
  count = 0;
  data: any;
  language: any;
  arrayRowsSize: any;
  arrayRows: any[] = [];
  itemCarrouselHeader: any[] = [];
  title: string;
  path: string;
  pathMob: string;
  innerHTML = '';
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;
  @ViewChild('componenteDinamico11', { read: ViewContainerRef }) viewContainerRef11: ViewContainerRef;
  @ViewChild('componenteDinamico12', { read: ViewContainerRef }) viewContainerRef12: ViewContainerRef;
  viewContainerRefs: any[] = []
  constructor(private componentFactoryResolver: ComponentFactoryResolver, public route: ActivatedRoute, public services: GeneralService) {
  }

  ngOnInit(): void {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });

  }



  ngAfterViewInit() {
    if (this.entity['type'] == 'landing') {

      this.data = this.entity['pages'];
      this.arrayRowsSize = this.entity['pagesLength'];
      for (let i = 1; i < this.arrayRowsSize + 1; i++) {
        this.entity['pages'][i].forEach(element => {
          
        });
        if (this.entity['pages'][i][0]['status']) {
          this.arrayRows.push(i);
          this.loadPageAt(i);
        }
      }
    } else
      if (this.entity['type'] === "gallery") {
        this.title = this.entity.title;
        this.pathMob = this.entity.pathMobile;
        this.path = this.entity.path;
        const factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselImgComponent);
        const ref = (this.viewContainerRefA(2)).createComponent(factory);
        ref.instance.data = this.entity.gallery;
        ref.instance.title = this.entity.title;
        // ref.instance.background = background;
        ref.changeDetectorRef.detectChanges();

      }
  }
  async loadPageAt(index: number) {
    if (this.arrayRows.length >= index) {

      this.services.getEntities(this.data[index][0]['route'] + '&locale=' + this.language).subscribe(data1 => {
        let info = data1.data[0];
        if (info.headerSection) {
          this.title = info.headerSection.title;
          this.pathMob = info.headerSection.pathMobile;
          this.path = info.headerSection.path;
          this.itemCarrouselHeader = info.headerSection.headerSectionItem;
        } else if (info.entityList) {
          info.entityList.forEach(element => {
            let type = element.typeDisplay;
            var background = element.background;
            this.newComponent(index, type, info, background)
          });


        } else if (info.socialNetwork) {
          this.newComponent(index, 'Cards', info, '')

        }



      });

    }


  }
  newComponent(index: number, type: string, info: any, background: string) {
    if (type === 'Simple') {
      const factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
      const ref = (this.viewContainerRefA(index)).createComponent(factory);
      ref.instance.data = info.entityList[0].entity;
      ref.instance.title = info.entityList[0].title;
      ref.instance.background = background;
      ref.changeDetectorRef.detectChanges();



    } else
      if (type === 'Carrousel') {

        if (info.entityList[0].entity) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.changeDetectorRef.detectChanges();
        }


      }

      else
        if (type === 'Cards') {
          const factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralDosComponent);
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.data = info.socialNetwork.socialNetworkItem;
          ref.instance.title = info.socialNetwork.title;
          ref.changeDetectorRef.detectChanges();

        }
        else
          if (type === "Lista de links") {

            if (info.entityList[0].entity) {
              const factory = this.componentFactoryResolver.resolveComponentFactory(ListaDeLinksComponent);
              const ref = (this.viewContainerRefA(index)).createComponent(factory);
              ref.instance.data = info.entityList[0].entity;
              ref.instance.title = info.entityList[0].title;
              ref.instance.background = background;
              ref.changeDetectorRef.detectChanges();
            }


          } else
            if (type === "Video + Formulario (en ingles)") {
              if (info.entityList[0].linkCode) {
                const factory = this.componentFactoryResolver.resolveComponentFactory(ContactoComponent);
                const ref = (this.viewContainerRefA(index)).createComponent(factory);
                ref.instance.linkCode = info.entityList[0].linkCode;
                ref.instance.title = info.entityList[0].title;
                ref.instance.background = background;
                ref.changeDetectorRef.detectChanges();

              }

            }

  }

  viewContainerRefA(index: number): any {
    switch (index) {
      case 2:
        return this.viewContainerRef2;
      case 3:
        return this.viewContainerRef3;
      case 4:
        return this.viewContainerRef4;
      case 5:
        return this.viewContainerRef5;
      case 6:
        return this.viewContainerRef6;
      case 7:
        return this.viewContainerRef7;
      case 8:
        return this.viewContainerRef8;
      case 9:
        return this.viewContainerRef9;
      case 10:
        return this.viewContainerRef10;
      case 11:
        return this.viewContainerRef11;
      case 12:
        return this.viewContainerRef12;
      default:
        // 
        break;
    }
  }
  url(element: any) {
    let url = '';
    if (element.route) {
      if(element.externalLink){
        url = element.route;

      }else{
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }

    }
    if (element.systemTitle) {
      url = element.systemTitle
      if(element.externalLink){
        url = element.systemTitle;

      }else{
      if (element.systemTitle[0] == '/') {
        url = element.systemTitle = element.systemTitle.replace('/', '')
      }
      url = '/' + this.language + '/' + url;
    }
    }

    return url;
  }
}
