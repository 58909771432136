import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsultaRequest } from 'src/app/shared/models/consultaRequest';
import Swal from 'sweetalert2';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { MatTooltip } from '@angular/material/tooltip';
import { FormContactoComponent } from '../form-contacto/form-contacto.component';

@Component({
  selector: 'app-formas-de-contacto-dos',
  templateUrl: './formas-de-contacto-dos.component.html',
  styleUrls: ['./formas-de-contacto-dos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormasDeContactoDosComponent implements OnInit {
  @ViewChild('animatedSection') animatedSection!: ElementRef<HTMLDivElement>;
  isInView = false;
  @Input() data: any
  @Input() esCurso: boolean = false
  @Input() fondo;
  @Input() fondoMob;
  @Input() color='blue';

  formDatos: FormGroup;
  type = "ingresa";
  carrer: string;
  venue: string;
  tel: Boolean = false;
  curso: any;
  sedes: any[] = [
    'Buenos Aires', 'Mendoza', 'Paraná', 'Rosario'
  ]
  showTooltips = {
    name: false,
    email: false,
    region: false
  };
  isMob: boolean

  @ViewChild('nameTooltip') nameTooltip: MatTooltip;
  @ViewChild('emailTooltip') emailTooltip: MatTooltip;
  @ViewChild('regionTooltip') regionTooltip: MatTooltip;
  constructor(public dialog: MatDialog, private form: FormBuilder, private services: GeneralService) {
    this.formDatos = this.form.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      region: ['', [Validators.required]],
      //phone: ['', [Validators.pattern(Patters.OnlyNumber)]],
      message: [''],
      mail_destinatario: ['']
    });
    this.checkScreenWidth();
    if (window.innerWidth <= 768) {
      this.isMob = true
    } else {
      this.isMob = false
    }
  }

  ngOnInit(): void {
    $(".fondo-contacto").css("background-image", 'url("../../../../assets/img/' + this.fondo + '")');

  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const element = this.animatedSection.nativeElement;
    const rect = element.getBoundingClientRect();
    const offset = 100;
    // Verifica si el elemento está dentro de la ventana de visualización
    const isVisible = rect.top < window.innerHeight - offset && rect.bottom > offset;
    // Activa la animación cuando el elemento está en vista
    if (isVisible && !this.isInView) {
      this.isInView = true;
    }

  }
  private checkScreenWidth() {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.isMob = true
      } else {
        this.isMob = false
      }

    });

  }
  updateFilter(field: string, value: any) {
    this.formDatos.get(field).setValue(value);
  }
  consultar() {
    if (this.formDatos.invalid) {
      this.formDatos.markAllAsTouched();
      if (this.formDatos.get('name').invalid) {
        this.nameTooltip.show();
      }
      if (this.formDatos.get('email').invalid) {
        this.emailTooltip.show();
      }
      if (this.formDatos.get('region').invalid) {
        this.regionTooltip.show();
      }
    } else {
      let datos = new ConsultaRequest();
      datos.email = this.formDatos.get('email').value;
      datos.name = this.formDatos.get('name').value;
      datos.region = this.formDatos.get('region').value;
      datos.isHome = false;
      datos.message = this.formDatos.get('message').value;
      datos.career = this.carrer;
      datos.mail_destinatario = this.data.mail_destination
      // datos.phone = this.formDatos.get('phone').value;
      datos.curso = this.curso;
      datos.venue = this.venue;
      datos.isPosgrado = false
      if (datos.venue && datos.career) {
        this.consultarEmblue(datos);
      }
      this.services.postConsulta(datos).then(function (response) {
        return response.json();
      }).then(res => {
        if (res.status == 1) {
          this.formDatos.reset()
          this.updateFilter('region', null)
          Swal.fire(
            'La consulta fue enviada con exito!',
            '',
            'success'
          )
        } else {
          Swal.fire(
            'La consulta fallo , lo sentimos',
            '',
            'error'
          )

        }
      }
      )

    }


  }
  consultarEmblue(datos: ConsultaRequest) {
    let atributos = (
      {
        career: carrera,
        name: nombre,
        phone: whatsapp,
        region: sede
      }
    ) => ({ carrera, nombre, whatsapp, sede });
    let eventoEmblue = {
      eventName: 'form_derecho',
      email: datos.email,
      attributes: atributos(datos)
    };
    if (datos.venue) {
      switch (datos.venue) {
        case "facultad-de-derecho":
          eventoEmblue.eventName = "form_derecho"
          break;
        case "facultad-de-psicologia-y-psicopedagogia":
          eventoEmblue.eventName = "form_psico"
          break;
        case "facultad-de-ciencias-medicas":
          eventoEmblue.eventName = "form_cs.medicas"
          break;
        case "facultad-de-ingenieria-y-ciencias-agrarias":
          eventoEmblue.eventName = "form_ingeniería"
          break;
        case "facultad-de-artes-y-ciencias-musicales":
          eventoEmblue.eventName = "form_musica"
          break;
        case "facultad-de-filosofia-y-letras":
          eventoEmblue.eventName = "form_filo"
          break;
        case "facultad-de-ciencias-economicas":
          eventoEmblue.eventName = "form_economicas"
          break;
        case "facultad-de-ciencias-sociales":
          eventoEmblue.eventName = "form_sociales"
          break;

        default:
          eventoEmblue.eventName = "form_derecho"
          break;
      }
    }

    if (this.type == "posgrados") {
      this.services.postEmblueFormPosgrados(eventoEmblue).subscribe(
        (data) => console.log({ message: ' Form emblue Posgrados', data: data }),
        error => console.log({ message: ' Form emblue Posgrados', error: error })
      )
    } else {
      this.services.postEmblueForm(eventoEmblue).subscribe(
        (data) => console.log({ message: 'Form emblue ', data: data }),
        error => console.log({ message: ' Form emblue', error: error })
      )
    }

  }
  openModal(): void {
    let dialogRef = this.dialog.open(FormContactoComponent, { panelClass: 'custom-dialog-container', data: { curso: null } });
    const sub = dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  windowOpen(url) {
    window.open(url)
  }
}
