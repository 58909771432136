
<div id="resultado-buscador" class="sede " (click)="redirect(carrera)"  [style.border-color]="borde ? carrera.color : ''" [ngClass]="{'borde': borde}" >
    <div *ngIf="detalleCurso" [style.background-color]="detalleCurso ? carrera.color : ''" class="detalleCurso">
        <p class="curso-text">METODOLOGIA DE LA INVESTIGACION</p>
    </div>
    <div class="sede-overlay"  [ngClass]="{'curso': detalleCurso}">
      <img *ngIf="carrera.pathMobile" [src]="carrera.pathMobile" alt="" class="img" onerror="this.src='../../../../assets/img/default.png'">
      <img *ngIf="!carrera.pathMobile" src="../../../../assets/img/default.png" alt="" class="img">
      <div class="overlay-text" [ngClass]="{'curso-overlay': detalleCurso}">
          <div>
              <p class="text text-medium nunito-regular" style="display: flex; margin-bottom: 9px; align-items: flex-end;" >{{carrera.modalidad}}
                  <ng-container *ngIf="carrera.modalidad == 'Presencial'">
                      <img *ngIf="!borde && posgrado"  src="../../../../assets/img/presencial-sm.png" alt="" class="icon-modalidad">
                      <svg *ngIf="borde || !posgrado"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="22" viewBox="228 30 18 22" class="icon-modalidad-b">
                        <image width="18" height="22" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAWCAYAAADNX8xBAAAAAXNSR0IArs4c6QAAAUpJREFUOE+t08ErZWEYx/HPbTZkKEnJPzATythNUZRCWYzs/QmaotSULXaT/AMWUjbKipKwUUpJmoVSNrJhspgrt1lMnPd2Tt107jnXvfNuf8/zfZ/39/zeguqnF3MYRRtusI2VtJZCFc4E9qpoFxjEc6WeBurGXcakQdrHeB5oDbM5oCD34zKpS5voHAM1gOYj735mgX4hGJ13FrGUBdrBtzwKJrGbBRrGcQ7oN7rwLwsUtFV8z4AN4SRva4k+g2BoHz5E4fyDwyg/C7h+e0m1QCZ1X/AVH3Ebgx5qSXZHnKEpfEYJj7EXTejEX5xiA5tpHo1FzVtor2FjSclBFMrpyIJi8rQRHL0DUFl6hZ4AasZ97EOdLOsB9APL9RIqPar1b2XdVQoTha28x+A0YNnsJ7Q0+LQyKCS29X+AXhqElNvDRGf41CCs+Ao/FjVleHU9hwAAAABJRU5ErkJggg==" data-name="presencial" transform="translate(228 30)"/>
                      </svg>
                                        
                  </ng-container>
                 
                  <ng-container *ngIf="carrera.modalidad == 'Virtual'">
                      <img *ngIf="!borde && posgrado" src="../../../../assets/img/online-sm.png" alt="" class="icon-modalidad">
                      <svg *ngIf="borde || !posgrado" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="22" viewBox="144 93 30 22" class="icon-modalidad-b"><image width="30" height="22" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAYAAADXYyzPAAAAAXNSR0IArs4c6QAAAP1JREFUSEvt1b8rR1EYx/HXNxZiMwhlNMisWKTMBn+F1eB/MNn8AWZZ/QVSdjFYCPkxSVJCeoab43aHc+vcDO4znU6f5/Oucz49z8BPDXCAteSu5PEMq3gL04BVNYSPkqQGr1lcN4GvMN0R/BMBvu3B8cf9U5fMWR+uXwPk/4XrT0ZmzO19LJeMcuJ1ifWmJVFppjoC36W+6XaK+yPMdQS+xwre60tiE3sdQSvbXWyl4GE8YzQT/FLTjWf2hWwSD9VT72A7s3kRpzVt7PALjGV4HGIjwBN4ymioJE3gGZxngsNnIcDHWGoBLiG9CfAJ5lu4jSAykdYXXlt4PH4Drjg+AkCedQ4AAAAASUVORK5CYII=" data-name="online" transform="translate(144 93)"/></svg>
                  </ng-container>
                  <ng-container *ngIf="carrera.modalidad == 'Híbrida'" style="display: flex;">
                     
                      <img *ngIf="!borde && posgrado" src="../../../../assets/img/online-sm.png" alt="" class="icon-modalidad" >
                      <img *ngIf="!borde && posgrado"  src="../../../../assets/img/presencial-sm.png" alt="" class="icon-modalidad" >

                      <svg *ngIf="borde || !posgrado" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="22" viewBox="228 30 18 22" class="icon-modalidad-b">
                        <image width="18" height="22" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAWCAYAAADNX8xBAAAAAXNSR0IArs4c6QAAAUpJREFUOE+t08ErZWEYx/HPbTZkKEnJPzATythNUZRCWYzs/QmaotSULXaT/AMWUjbKipKwUUpJmoVSNrJhspgrt1lMnPd2Tt107jnXvfNuf8/zfZ/39/zeguqnF3MYRRtusI2VtJZCFc4E9qpoFxjEc6WeBurGXcakQdrHeB5oDbM5oCD34zKpS5voHAM1gOYj735mgX4hGJ13FrGUBdrBtzwKJrGbBRrGcQ7oN7rwLwsUtFV8z4AN4SRva4k+g2BoHz5E4fyDwyg/C7h+e0m1QCZ1X/AVH3Ebgx5qSXZHnKEpfEYJj7EXTejEX5xiA5tpHo1FzVtor2FjSclBFMrpyIJi8rQRHL0DUFl6hZ4AasZ97EOdLOsB9APL9RIqPar1b2XdVQoTha28x+A0YNnsJ7Q0+LQyKCS29X+AXhqElNvDRGf41CCs+Ao/FjVleHU9hwAAAABJRU5ErkJggg==" data-name="presencial" transform="translate(228 30)"/>
                      </svg>
                      <svg *ngIf="borde || !posgrado" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="22" viewBox="144 93 30 22" class="icon-modalidad-b"><image width="30" height="22" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAYAAADXYyzPAAAAAXNSR0IArs4c6QAAAP1JREFUSEvt1b8rR1EYx/HXNxZiMwhlNMisWKTMBn+F1eB/MNn8AWZZ/QVSdjFYCPkxSVJCeoab43aHc+vcDO4znU6f5/Oucz49z8BPDXCAteSu5PEMq3gL04BVNYSPkqQGr1lcN4GvMN0R/BMBvu3B8cf9U5fMWR+uXwPk/4XrT0ZmzO19LJeMcuJ1ifWmJVFppjoC36W+6XaK+yPMdQS+xwre60tiE3sdQSvbXWyl4GE8YzQT/FLTjWf2hWwSD9VT72A7s3kRpzVt7PALjGV4HGIjwBN4ymioJE3gGZxngsNnIcDHWGoBLiG9CfAJ5lu4jSAykdYXXlt4PH4Drjg+AkCedQ4AAAAASUVORK5CYII=" data-name="online" transform="translate(144 93)"/></svg>
                  </ng-container>
              </p>
              <p *ngIf="!borde && posgrado" class="text dorado quicksand-regular" style="background-color: #C1B283; padding: 8px;    padding-right: 20px;  margin-bottom: 5px;     width: fit-content;">{{carrera.nivel}}</p>
              <p class="text text-small facultad borde-left nunito-regular"  [style.border-color]="carrera.color"  *ngIf="carrera.facultades && carrera.facultades.length >0">{{carrera.facultades[0]?.title}}</p>
          </div>
          <div>
             <!--  <p class="text text-large">{{cortarTitulo(carrera) }}</p> -->
             <!--  <p class="text text-small"><span class="text-small-b">INICIO:</span> {{  formatearFecha(carrera) }}</p>
              <p class="text text-small"><span class="text-small-b">SEDE:</span>  {{carrera.sede}}</p> -->
             <!--  <p class="text text-small  nunito-regular facultad" style="" [style.border-left]="'18px solid ' + carrera.color" *ngIf="carrera.facultades && carrera.facultades.length >0">{{carrera.facultades[0]?.title}}</p> -->
          </div>
          <div>
              <p class="text text-large quicksand-regular">{{cortarTitulo(carrera) }}</p>
              <p class="text text-small nunito-regular"><span class="nunito-bold">INICIO:</span> {{  formatearFecha(carrera) }}</p>
              <p class="text text-small2 nunito-regular" style="margin-bottom: 15px;"><span class="nunito-bold">SEDE:</span>  {{carrera.sede}}</p>
              <a [href]="language +carrera.linkCareer">
                  <p  class="info nunito-bold ">MÁS INFO >></p>
              </a>
          </div>
          
      </div>
      
    </div>
</div>
