import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss']
})
export class VenuesComponent implements OnInit {
  @Input() data: any
  dataLists: any = []
  language: any;
  constructor(private services: GeneralService, private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
    var url = location.href;
    var indexL = url.indexOf(this.language) + this.language.length + 1;
    var id = url.slice(indexL, url.length)

  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.splitListIntoThree()
  }
  splitListIntoThree() {
    const venueList = this.data.venuelist; // Lista original
    const totalItems = venueList.length;
    const itemsPerList = Math.ceil(totalItems / 3); // Redondear hacia arriba para no perder elementos

    this.dataLists = [
      venueList.slice(0, itemsPerList),
      venueList.slice(itemsPerList, itemsPerList * 2),
      venueList.slice(itemsPerList * 2)
    ];
  }
  deg = 0;
  abrirPanel(i) {
    if ($('#collapse' + i).hasClass('show')) {
      $('.show').addClass('show');
      $('#collapse' + i).removeClass('show');
      this.deg = 0;
    } else {
      $('.show').removeClass('show');
      $('#collapse' + i).addClass('show');
      this.deg = 180;
    }


  }
}

