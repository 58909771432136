import { Component, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent {
  @Input() options: any[] = [];
  @Input() placeholder: string = 'Select';
  @Input() placeholderAll: string;
  @Output() selectionChange = new EventEmitter<any>();
  @Input() color='blue'
  @Input() id = '';
  @Input() height: any
  @Input() italic: boolean
  @Input() curso: boolean = false
  @Input() colorArrow="gris"
  @Input() optionPlaceholder: boolean = true
  selectedOption: any = '';
  dropdownOpen: boolean = false;
  hoverOption: any = null;
  constructor(private elementRef: ElementRef) {
   }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectOption(option) {
    this.selectedOption = option || null;
    this.dropdownOpen = false;
    this.selectionChange.emit(option.id || option);
    this.toggleDropdown()
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }
  @Input()
  set reset(value: boolean) {
    if (value) {
      this.selectedOption = null;
    }
  }
}
