import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IframeComponent } from 'src/app/shared/components/iframe/iframe.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nuestras-sedes',
  templateUrl: './nuestras-sedes.component.html',
  styleUrls: ['./nuestras-sedes.component.scss']
})
export class NuestrasSedesComponent implements OnInit {
  @ViewChild('animatedSection') animatedSection!: ElementRef<HTMLDivElement>;
  isInView = false;
  @Input() data: any = {};
  language: any;
  apiUrl: any;
  @Input() sedes = []
  constructor(public dialog: MatDialog, private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = (params['language']) ? params['language'] : 'es';
    });

    this.apiUrl = environment.apiUrl;
  }

  getFormattedAddress(address: string) {
    if (window.innerWidth <= 768) {
      return address.replace(/,/g, ',<br>');
    }
    return address;
  }
  ngOnInit(): void {
  }
  windowOpen(url) {
    window.open(url)
  }
  redirecWhatsApp(tel: string) {
    const url = `https://api.whatsapp.com/send?phone=${tel}`;
    window.open(url, '_blank');
  }
  redirecGoogleMaps(direccion: string): string {
    const baseUrl = 'https://www.google.com/maps/search/?api=1&query=';
    const encodedDireccion = encodeURIComponent(direccion);
    return `${baseUrl}${encodedDireccion}`;
  }
  redirec(link) {
    window.location.href = link
  }


  openModal(url: any) {
    this.dialog.open(IframeComponent, {
      width: '90%',
      height: '80%',
      data: { url }
    });
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const element = this.animatedSection.nativeElement;
    const rect = element.getBoundingClientRect();
    const elementHeight = rect.height; // Altura total del elemento
    const visibleHeight = Math.min(window.innerHeight, rect.bottom) - Math.max(0, rect.top); // Cuánto del elemento es visible
    
    const visibilityRatio = visibleHeight / elementHeight; // Calcula el porcentaje visible
    const isVisible = visibilityRatio >= 0.2;  
    if (isVisible && !this.isInView) {
      this.animateSequentially();
    }
  }
  
  animatedIndexes: Set<number> = new Set();

  animateSequentially() {
    this.sedes.forEach((_, i) => {
      setTimeout(() => {
        this.animatedIndexes.add(i);
      }, i * 250); 
    });
  }

  shouldAnimate(index: number): boolean {
    return this.animatedIndexes.has(index);
  }
}
