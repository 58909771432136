<div *ngIf="isDesktop " class="carousel-inner" >

  <div class="carousel-item active " *ngFor='let item of images' >
      <div    [style.margin-left]="marginDesk"  [style.margin-right]="marginDesk"  [id]="item.id" [routerLink]="'/' + language+ item.redirect" [ngClass]='(item.state== "actived")?"actived" : "inactive"'>
        <img class="img-title w-100 " [src]="apiUrl+item.path">
        
      </div>
  </div>
  <div *ngIf="images.length > 1"  class="bullets" >
    <ng-container *ngFor="let b of images;index as i">
      <span class="bullet" [class.active]="positionDesk === i" (click)="cambiarSliderDesk(i)"></span>

    </ng-container>
    
  </div>
  
</div>
<div *ngIf="!isDesktop " class="carousel-inner">
  <!-- [@enterState]='item.state'-->
 <!--  <div class="carousel-buttons">
    <button *ngIf="!ingreso" class="btn btn-primary left-btn" [ngClass]="{ 'btn-posgrado': !ingreso }" (click)="cambiarSliderMob(positionMob - 1)">&lt;</button>
    <button *ngIf="!ingreso" class="btn btn-primary right-btn" [ngClass]="{ 'btn-posgrado': !ingreso }" (click)="cambiarSliderMob( positionMob + 1)">&gt;</button>
  </div> -->
  <div class="carousel-item active " *ngFor='let item of images' >
      <div [style.margin-left]="marginMob"  [style.margin-right]="marginMob"  [id]="item.id" [routerLink]="'/' + language+ item.redirect"  [ngClass]='(item.state== "actived")?"actived" : "inactive"'>
        <img class="img-title w-100 " [src]="apiUrl+item.pathMobile" >
        
      </div>
  </div>
  <div class="bullets" >
    <ng-container *ngFor="let b of images;index as i">
      <span class="bullet" [class.active]="positionMob === i" (click)="cambiarSliderMob(i)"></span>

    </ng-container>
    
  </div>
</div>

