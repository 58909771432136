import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-areas-de-conocimiento',
  templateUrl: './areas-de-conocimiento.component.html',
  styleUrls: ['./areas-de-conocimiento.component.scss']
})
export class AreasDeConocimientoComponent implements OnInit {
  @Input() data: any;
  @Input() fondo: any;
  @Input() fondoMob: any;
  @Input() cursos: boolean = false;
  width = false;
  posgrados: any[] = [];
  items: any
  constructor() { }

  ordenarArray(items: any[]): { arriba: any[], abajo: any[] } {
    console.log(items)
    let arriba;
    let abajo

    const typedItems = items as unknown as {
      areas_tematicas_not_check_school: any[];
      areas_tematicas_check_school: any[];
    };
    arriba = typedItems?.areas_tematicas_not_check_school
    abajo = typedItems?.areas_tematicas_check_school


    return { arriba, abajo };
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
   if(this.fondo && this.fondoMob)  $(".fondo-area").css("background-image", 'url("../../../../assets/img/' + ((window.innerWidth >= 768) ? this.fondo : this.fondoMob) + '")');
  }
  ngOnInit(): void {
    if(this.fondo && this.fondoMob)  $(".fondo-area").css("background-image", 'url("../../../../assets/img/' + ((window.innerWidth >= 768) ? this.fondo : this.fondoMob) + '")');

    this.items = this.ordenarArray(this.data)
  }

}
