
<div class="posgrados-container" >
<app-portada *ngIf="dataPortada && area" [area]="area" [data]="dataPortada"  [marginTitle]="75"  [heightDesk]="'200px'"  [heightMob]="'250px'"  ></app-portada>
<app-buscador-posgrados-tematico *ngIf="area" [area]="area.name" ></app-buscador-posgrados-tematico>

<section class="">

  <app-banner></app-banner>

</section>
<section class="">
    
  <app-formas-de-contacto-dos [data]="dataContacto"></app-formas-de-contacto-dos>

</section>
