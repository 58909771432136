<div class="panel-group" id="accordion">
    <div class="panel panel-default">
        <div class="panel-heading hidden">
            <h4 class="panel-title">
                <div [style.background]="data.color" class="accordion-toggle  " data-toggle="collapse"
                    data-parent="#accordion" (click)='abrirPanel(1)'>
                    <h3 class="titulo sinbord quicksand-medium w-100" [style.color]="data.title_color">{{data.title}}
                    </h3>
                    <img class="flecha-panel" src="./assets/img/flechita-blanca.svg"
                        [style.transform]="'rotate(' + deg + 'deg)'" alt="">

                </div>
            </h4>
        </div>
        <div [id]="'collapse1'" class="panel-collapse collapse in ">
            <div class="panel-body list-mob">
                <ul class="responsive-list">
                    <li *ngFor="let item of data.venuelist">
                        <a class="nunito-regular" [href]="'/'+language + item.route">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
            <div class="panel-body list-desk">
                <ul *ngFor="let list of dataLists" class="responsive-list">
                    <li *ngFor="let item of list">
                        <a class="nunito-regular" [href]="'/'+language + item.route">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>