import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { MatTooltip } from '@angular/material/tooltip';
import { FormContactoComponent } from '../form-contacto/form-contacto.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-formas-de-contacto-tres',
  templateUrl: './formas-de-contacto-tres.component.html',
  styleUrls: ['./formas-de-contacto-tres.component.scss'],
})
export class FormasDeContactoTresComponent implements OnInit {
  @ViewChild('animatedSection') animatedSection!: ElementRef<HTMLDivElement>;
  isInView = false;

  @Input() data: any
  @Input() blue: boolean = false
  @Input() fondo;
  @Input() fondoMob;

  type = "ingresa";
  carrer: string;
  venue: string;
  tel: Boolean = false;
  curso: any;

  showTooltips = {
    name: false,
    email: false,
    region: false
  };
  isMob: boolean
  apiUrl;
  @ViewChild('nameTooltip') nameTooltip: MatTooltip;
  @ViewChild('emailTooltip') emailTooltip: MatTooltip;
  @ViewChild('regionTooltip') regionTooltip: MatTooltip;
  constructor(public dialog: MatDialog, private form: FormBuilder, private services: GeneralService) {
    this.apiUrl = environment.apiUrl;
    this.checkScreenWidth();
    if (window.innerWidth <= 768) {
      this.isMob = true
    } else {
      this.isMob = false
    }
  }

  ngOnInit(): void {
    $(".fondo-contacto").css("background-image", 'url("../../../../assets/img/' + this.fondo + '")');

  }
  private checkScreenWidth() {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.isMob = true
      } else {
        this.isMob = false
      }

    });

  }


  openModal(): void {
    let dialogRef = this.dialog.open(FormContactoComponent, { panelClass: 'custom-dialog-container', data: { curso: null } });
    const sub = dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  windowOpen(url) {
    window.open(url)
  }


  @HostListener('window:scroll', [])
  onScroll(): void {
    const element = this.animatedSection.nativeElement;
    const rect = element.getBoundingClientRect();
    const offset = 100;
    // Verifica si el elemento está dentro de la ventana de visualización
    const isVisible = rect.top < window.innerHeight - offset && rect.bottom > offset;
    // Activa la animación cuando el elemento está en vista
    if (isVisible && !this.isInView) {
      this.isInView = true;
    }

  }
}
