
<div class="ingreso-container" 
[style.background-image]="'url(./assets/img/' + (sede ? 'fondo-web-sedes.jpg' : 'fondo-web.jpg') + ')'"
>
  <ng-container #componenteDinamico1></ng-container>
  <ng-container #componenteDinamico2></ng-container>
  <ng-container #componenteDinamico3></ng-container>
  <ng-container #componenteDinamico4></ng-container>
  <ng-container #componenteDinamico5></ng-container>
  <ng-container #componenteDinamico6></ng-container>
  <ng-container #componenteDinamico7></ng-container>
  <ng-container #componenteDinamico8></ng-container>
  <ng-container #componenteDinamico9></ng-container>
  <ng-container #componenteDinamico10></ng-container>
</div>