<div [style.position]="'relavite'" [style.margin]="'30px 0px'">
  <div id="slider"  [style.margin-left]="(isDesktop) ? marginDesk :marginMob"
  [style.margin-right]="(isDesktop) ? marginDesk :marginMob" >
    <figure [style.left]="sliderPosition">
      <img *ngFor="let item of images; let i = index" [src]=" (isDesktop) ? apiUrl +item.path : apiUrl +item.pathMobile"
        class="img-title"[id]="item.id"
        [routerLink]="'/' + language + item.redirect" [class.active]="positionDesk === i">
    </figure>
  </div>

  <div *ngIf="images.length > 1" class="bullets">
    <ng-container *ngFor="let b of images; index as i">
      <span class="bullet" [class.active]="positionDesk === i" (click)="setPosition(i)"></span>
    </ng-container>
  </div>
</div>