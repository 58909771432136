import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormContactoComponent } from 'src/app/shared/widgets/form-contacto/form-contacto.component';

@Component({
  selector: 'app-formas-de-contacto',
  templateUrl: './formas-de-contacto.component.html',
  styleUrls: ['./formas-de-contacto.component.scss']
})
export class FormasDeContactoComponent implements OnInit {
  appStore = false;
  googlePlay = true;
  redes: any[] = [
    {
      sede: 'Buenos Aires',
      facebook: 'https://es-la.facebook.com/ucaingreso/',
      instagram: 'https://www.instagram.com/ingreso.uca/?hl=es'
      , whatsapp: 'https://api.whatsapp.com/send?phone=5491130234624&app=facebook&entry_point=page_cta'
    },
    {
      sede: 'Mendoza',
      facebook: 'https://es-la.facebook.com/UCA-Ingreso-Mendoza-402324053882458/',
      instagram: 'https://www.instagram.com/ucaingresomendoza/?hl=es-la',
      whatsapp: '',
    },
    {
      sede: 'Paraná',
      facebook: 'https://www.facebook.com/UCAIngresoParana/',
      instagram: 'https://www.instagram.com/ucaingresoparana/?hl=es-la',
      whatsapp: 'https://api.whatsapp.com/send?phone=543434735453&app=facebook&entry_point=page_cta'
    },
    {
      sede: 'Rosario',
      facebook: 'https://www.facebook.com/ucaingresorosario/',
      instagram: 'https://www.instagram.com/ucaingresorosario/?hl=es-la',
      whatsapp: 'https://api.whatsapp.com/send?phone=543412662910&app=facebook&entry_point=page_cta'
    },
  ]
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    let element = document.getElementById("googlePlay");
    element.classList.add('visible');

  }

  openModal(): void {
    let dialogRef = this.dialog.open(FormContactoComponent, { panelClass: 'custom-dialog-container' ,data: { curso:null }});
    const sub = dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialog.closeAll();
    });

  }
  windowOpen(url) {
    window.open(url)
  }


}
