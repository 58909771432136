import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @Input() data: any[] = []
  @Input() ingreso: boolean = true
  dataTres: any[] = []
  noDestacadas: any[] = []
  destacadas: any[] = []
  language: any
  constructor(private route: ActivatedRoute) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });
  }

  ngOnInit(): void {

    for (let i = 0; i < this.data.length; i++) {
      const element = this.data[i].label
      this.dataTres.push(this.data[i])
      /* if(element ==  "UCA Internacional / EducationUSA" || element =='Escuela de Idiomas' || element == "Sistemas de Bibliotecas UCA" ){
        this.dataTres.push(this.data[i])
      } */

    }
    this.noDestacadas = this.dataTres.filter(d => d.estado && !d.destacada);
    console.log("No destacadas", this.noDestacadas)
    this.destacadas = this.dataTres.filter(d => d.estado && d.destacada);


  }
  redirect(link) {
    if (link.includes('http://')) {
      link = window.location.href = link;
    } else {
      link = "/" + this.language + '/' + link
    }
    console.log(environment.apiUrl +   link)
    //window.location.href = link
  }

}
