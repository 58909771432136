import { Component, Input, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @ViewChild('animatedSection') animatedSection!: ElementRef<HTMLDivElement>;
  isInView = false;
  @Input() images: any[] = []
  @Input() ingreso: boolean = true
  @Input() marginDesk: string = "0px"
  @Input() marginMob: string = "0px"
  apiUrl = environment.apiUrl;
  isDesktop: boolean;
  language: any;

  constructor(private aRouter: ActivatedRoute) {
    this.language = (this.aRouter.snapshot.params['language']) ? this.aRouter.snapshot.params['language'] : 'es';

    this.isDesktop = window.innerWidth > 768;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktop = event.target.innerWidth > 768;
  }
  positionDesk = 0;
  positionMob = 0;

  sliderPosition: string = '0%';  // Variable que controlará el 'left' del slider

  // Método para cambiar la posición cuando se hace clic en un bullet
  setPosition(index: number) {
    this.positionDesk = index;
    this.updateSliderPosition();
  }

  // Método para actualizar la posición del slider
  updateSliderPosition() {
    this.sliderPosition = `-${this.positionDesk * 100}%`;  // 20% es el ancho de cada imagen
  }

  // Opcional: Puedes agregar un intervalo para que las imágenes cambien automáticamente
  ngOnInit() {
    setInterval(() => {
      this.positionDesk = (this.positionDesk + 1) % this.images.length;
      this.updateSliderPosition();
    }, 5000);  // Cambia cada 3 segundos*/
  }

}
